import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import cx from 'classnames';
import { useRouter } from 'next/router';
import { checkValidEmail } from '@/lib/muzology';
import { beginCheckout, deleteLocalStorage, writeToLocalStorage } from '@/components/Checkout/utils/CheckoutProcess';
import { useAnalytics } from '@/analytics/AnalyticsProvider';
import { identifyUser } from '@/lib/analytics';
import * as Events from '@/analytics/events';
import { createUrlWithParams } from '@/lib/urls';
import { CheckoutError, NewParentAccount, NewParentRegister, NewTeacherRegister } from '@/analytics/events';
import { submitParentFreeTrial } from '@/lib/checkout_helpers/submitParentFreeTrail';
import { handlePreCheckout } from '@/lib/checkout_helpers/handlePreCheckout';

export const EmailSignup = ({ id, className = '', buttonText = null, metadata = {}, createTest = false, showMobile, setRedirectModal, setInactiveAccount }) => {
    const analytics = useAnalytics();
    const router = useRouter();
    const timestamp = Date.now();

    const defaultValues = {
        email: null,
        anonymousID: analytics.getAnonymousId(), // logRocketSessionURL: analytics.getLogrocketSessionURL(),
        metadata: {
            timestamp
        }
    };

    const [isLoading, setLoading] = React.useState(false);

    if (createTest || Boolean(process.env.NEXT_PUBLIC_FILL_TEST_DATA)) {
        defaultValues.email = `test-${timestamp}@muzology.com`;
    }

    const {
        register,
        handleSubmit,
        watch,
        setError,
        formState: { errors }
    } = useForm({ defaultValues });

    const emailValue = watch('email');

    const onSubmitBeginCheckout = useCallback(
        (data) => {
            const config = {
                data,
                router,
                analytics,
                beginCheckout,
                setLoading,
                setError,
                id
            };
            return handlePreCheckout(config);
        },
        [analytics, id, router, setError]
    );

    const onSubmitParentFreeTrial = useCallback(
        (data) => {
            const config = {
                data,
                analytics,
                setLoading,
                setError,
                setRedirectModal,
                setInactiveAccount,
                router
            };
            return submitParentFreeTrial(config);
        },
        [analytics, setError, setRedirectModal, router]
    );

    const isParentsPage = router.pathname === '/parents';
    const onSubmit = useCallback(
        (data) => {
            return isParentsPage ? onSubmitParentFreeTrial(data) : onSubmitBeginCheckout(data);
        },
        [isParentsPage, onSubmitParentFreeTrial, onSubmitBeginCheckout]
    );

    // snag the onBlur event
    const props = register('email', { required: true, validate: { validEmail: checkValidEmail } });
    // const { onBlur } = props;

    const [focus, setFocus] = React.useState(false);
    const onBlur = (event) => {
        setFocus(false);
        return props.onBlur(event);
    };
    const onFocus = (event) => {
        setFocus(true);
    };

    // pre-fetch the possible destination page
    useEffect(() => {
        if (router.pathname === '/parents') {
            router.prefetch('/checkout');
        } else {
            router.prefetch('/onboarding');
        }
    }, [router]);

    return (
        <div id={id + '-container'} className={cx('hidden xs2:flex flex-col gap-4 m-auto w-full max-w-[600px]', { flex: showMobile })}>
            <div
                className={cx('hidden fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 justify-center items-center hidden', {
                    flex: isLoading
                })}
            ></div>
            <form id={id} name='Email Signup Form' onSubmit={handleSubmit(onSubmit)} className='flex flex-col sm:flex-row gap-4 w-full'>
                <div className='w-full'>
                    <input
                        id={Number(id) + '-input'}
                        placeholder={focus ? '' : 'Your email'}
                        {...props}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        className={`${className} text-left placeholder-current w-[100%] lg:w-[100%] h-[54px] px-4 py-5 rounded-xl border-2 border-solid justify-between items-center inline-flex text-[17px]] font-normal font-Poppins leading-loose tracking-wide`}
                    />
                    {errors.email && <div className='text-red-500 mt-1 text-sm md:text-[17px] text-center'>Please enter valid email</div>}
                </div>

                <button
                    id={'submit-' + id}
                    className='whitespace-nowrap sm:max-w-[190px] lg:max-w-[213px] h-[54px] px-8 py-5 bg-[#6F5FE6] rounded-2xl border-solid border-[1px] border-none justify-center items-center gap-4 inline-flex hover:bg-indigo-600 hover:border-indigo-600 hover:shadow-lg hover:scale-105 transform transition-all duration-300 ease-in-out active:bg-indigo-700 active:border-indigo-700 active:shadow-none active:scale-100'
                >
                    <div className='text-[#FCFCFC]  text-center text-neutral-50 text-[20px] font-semibold font-Poppins leading-loose tracking-wide'>
                        {buttonText ?? 'Get Started'}
                    </div>
                </button>
            </form>
        </div>
    );
};

export default EmailSignup;
