import React from 'react';
import ImageBarSection from '@/components/Trustbar2/ImageBarSection';
import { ImageBarBoxes } from '../Trustbar2/ImageBarBoxes';
import { Trustbar2 } from '@/components/Trustbar2';
import { Heading2 } from '@/components/Views/Heading';
import EmailSignup from './EmailSignup';
import NormalText from "@/components/Views/Text";
import StructuredText from "@/components/ReusableUI/StructuredText";

const ResultsSection = ({ id, title = null, text = null, trustGallery, awardGallery, content = null, setRedirectModal, setInactiveAccount }) => {
    return (
        <section id={id} className='w-full default-py default-px pt-10 bg-cover bg-white md:bg-results-section text-center '>
            {title && <Heading2
                className='text-left md:text-center text-black  mt-0 md:mt-10 mx-auto font-Poppins font-semibold'
                heading={title}
                style={{ fontFamily: 'Poppins' }}
            />}

            {text && <NormalText
                className='font-normal text-zinc-800 text-center sm:text-left leading-[1.65]  font-light font-Poppins leading-normal md:leading-[30px] mt-5 max-w-[1100px] mx-auto'
                style={{ fontFamily: 'Poppins' }}
            >
                {text}
            </NormalText>}

            {content && <StructuredText
                data={content}
                styleName='rich-text-new center-header'
                className='font-normal text-zinc-800 text-center sm:text-left leading-[1.65]  font-light font-Poppins leading-normal md:leading-[30px] mt-5 max-w-[1100px] mx-auto'
            />}

            {/*
            <div className=' pt-5 lg:pt-0 py-0 lg:py-5'>
                <ImageBarBoxes images={trustGallery} className='my-0 hidden lg:block' />
                <ImageBarBoxes images={trustGallery} className='block lg:hidden mb-4 sm:mb-6 mt-0' />
            </div>
            */}

            <div className='mt-10 sm:mt-[30px] sm:mb-[50px] w-full flex'>
                <EmailSignup
                    id={id + '-email-signup'}
                    className='border-[#1CC8E1] text-[black] bg-[transparent]'
                    showMobile={false}
                    setRedirectModal={setRedirectModal}
                    setInactiveAccount={setInactiveAccount}
                />
            </div>

            <div>
                <ImageBarSection grid={true} images={awardGallery} className=' mb-16 hidden lg:block' />
                <ImageBarSection grid={true} images={awardGallery} className=' block lg:hidden mb-4 sm:mb-10 mt-2 ' />
            </div>

        </section>
    );
};

export default ResultsSection;
